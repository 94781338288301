<!-- <app-breadcrumb [title]="'ادارة العملاء'"></app-breadcrumb>
<div class="container-fluid">
  <div class="row">
    <div class="col-xl-12 xl-60">
      <div class="row"  *ngFor="let item of Client_List">
        <div class="col-xl-12">
          <div class="card">
            <div class="blog-box blog-list row">
              <div class="col-sm-12">
                <div class="d-flex justify-content-end pt-3">
                  <a href="">
                  <i class="icon-marker-alt p-2 mx-1 edit-btn" style="font-size: 23px;text-align: center;color: rgba(35, 118, 241, 0.667)" (click)="updateClient(item)" ></i>
                  </a>
                  <a href="">
                  <i class="icon-trash p-2 mx-1 delete-btn" style="font-size: 23px; ;text-align: center;color: red" (click)=" DeleteClient(item.clientId)" ></i>

                  </a>

                </div>
              </div>
              <div class="col-sm-5 d-flex justify-content-center">
                <img class="img-fluid sm-100-w" src="./assets/images/MainDirectory/{{item.logoPath}}"  alt="">
              </div>
              <div class="col-sm-7">
                <div class="blog-details">
                  <div class="blog-date digits"><span>05</span> January 2019</div>
                  <h6>{{item.clientTypeTitle}}</h6>
                  <div class="blog-bottom-content">
                    <ul class="blog-social">
                      <li>{{item.name}}</li>
                      <li class="blog-social">{{item.clientTypeTitle}}</li>
                    </ul>
                    <hr>
                    <p class="mt-0 client-body">
                      <span>{{item.address}}</span>
                      <span>{{item.cityTitle}}</span>
                      <span>{{item.governorateTitle}}</span>
                    </p>
                  </div>
                </div>
              </div> 
            </div>
          </div>
        </div>
    </div>
  </div>
</div> -->

<app-breadcrumb [title]="'ادارة العملاء'"> </app-breadcrumb>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 project-list">
      <div class="card">
        <div class="row ">
          <div class="col-sm-6">
            <div class="form-group" style="margin-bottom: 0px">
              <label></label>

            </div>
          </div>
          <div class="col-sm-6">
            <div class="text-right" style="margin-top: 15px; margin-left: 15px">
              <div class="form-group mb-0 mr-0"></div>

              <div class="addnew-btn" (click)="NavigateToInsertClient()" appActionNew>


                <button class="btn btn-success pull-right" type="button">
                  <span class="m-2">جديد</span> <i class="fa fa-plus"></i>
                </button>
              </div>



            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12">
      <div class="card" style="margin: 3px">
        <div class="card-body">
          <div class="tab-content" id="top-tabContent">
            <div class="tab-pane fade show active" id="top-home" role="tabpanel" aria-labelledby="top-home-tab">
              <div class="row">
                  <div *ngFor="let item of Client_List" class="col-xl-4 col-lg-6 mb-4">
                  <div class="project-box shadow">
                    <h6></h6>
                    <div class="media Contain-Style mt-2">
                      <i class="fas fa-layer-group mr-2 pt-1" style="color: darkgrey"></i>
                    </div>
                    <!-- <div
                      style="background: url('https://alnada-riskapi.azurewebsites.net//img/{{item.logoPath}}'); width: 200px; height: 150px">
                    </div> -->
                    <!-- <div class="Category-Img-Title" style="background-image: url(https://alnada-riskapi.azurewebsites.net//'{{item.logoPath}} )  ;background-size: cover;width: 230px;height: 230px;">
                      {{item.logoPath}}
                  </div> -->
                    <img [src]=" img+item.logoPath " >
                    <div class="row" style="margin-left: 0px; margin-right: 0px">

                      <div class="col-12 description-Style" style=" padding-left: 0px;
                                    padding-right: 0px;
                                    margin-top: 10px;
                                    margin-bottom: 15px;">
                      </div>
                    </div>
                    <div class="row details" style="margin-left: 0px; margin-right: 5px">
                      <div class="col-5"><span>الأسم</span></div>
                      <div class="col-7" style="color: #eb2067">
                        <span style="color: #eb2067">
                          {{item.name}}
                        </span>
                      </div>
                      <div class="col-5"><span>أسم المستخدم </span></div>
                      <div class="col-7" style="color: #eb2067">
                        <span style="color: #eb2067">
                          {{item.userName}}

                        </span>
                      </div>
                      <div class="col-5"><span>الموبايل </span></div>
                      <div class="col-7" style="color: #eb2067">
                        <span style="color: #eb2067">
                          {{item.mobile}}
                        </span>
                      </div>
                      <div class="col-5"><span>العنوان </span></div>
                      <div class="col-7" style="color: #eb2067">
                        <span style="color: #eb2067">
                          {{item.address}}
                        </span>
                      </div>
                      <div class="col-5"><span>نوع العميل </span></div>
                      <div class="col-7" style="color: #eb2067">
                        <span style="color: #eb2067">
                          {{item.companyTypeName}}
                        </span>
                      </div>
                      <div class="col-5"><span> محافظه </span></div>
                      <div class="col-7" style="color: #eb2067">
                        <span style="color: #eb2067">{{item.governoratName}} </span>
                      </div>
                      <div class="col-5"><span> مدينة </span></div>
                      <div class="col-7" style="color: #eb2067">
                        <span style="color: #eb2067">{{item.cityName}} </span>
                      </div>
                    </div>
                    <div class="row" style="
                        margin-left: 0px;
                        margin-right: 0px;
                        margin-bottom: 10px;
                      ">
                    
                      <div class="col-6 mt-3">
                        <button class="btn btn-success ml-2" appActionEdit (click)="updateClient(item.id)">
                          تعديل
                        </button>
                      </div>
                      <div class="col-6 mt-3">
                        <button class="btn btn-danger  mr-2" appActionDelete (click)=" DeleteClient(item.id)">
                          حذف
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="SeeMoreView" class="col-12 felxCenter">
                  <button (click)="pageChanged()" [disabled]="disableBtn" class="btn btn-outline-primary ">عرض المزيد</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>