export const environment = {
  production: true,
  firebase: {
    apiKey: "Your Api Key",
    authDomain: "Your Auth Domain",
    databaseURL: "Your Database Url",
    projectId: "Your Project Id",
    storageBucket: "Your StorageBucket url",
    messagingSenderId: "Your Sender Id"
  },
// Server_URL:'https://localhost:7296/api'
  // Server_URL:'https://alnada-riskapi.azurewebsites.net/api',
  // Server_URL_Image:'https://alnada-riskapi.azurewebsites.net/',
  
  
  Server_URL:'https://alnada-riskapi.azurewebsites.net/api',
  Server_URL_Image:'https://alnada-riskapi.azurewebsites.net/',
 
 
 
  // Server_URL:'https://alnada-riskapi.azurewebsites.net/api'
};
 