import { Router } from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IsNotLoginGuard implements CanActivate {
  
constructor(private router:Router) {
}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

  
 if(localStorage.getItem('RiskAuthorization') !=null)
 {
  this.router.navigate([localStorage.getItem('defaultMenuUrl')]);

  return true;

 }
else
    return true;
  }
  
}
